import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/opt/atlassian/pipelines/agent/build/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/atlassian/pipelines/agent/build/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/opt/atlassian/pipelines/agent/build/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import i18n_yfWm7jX06p from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_i8AMfKeYnY from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import google_login_8VXbHAWZpS from "/opt/atlassian/pipelines/agent/build/plugins/google-login.js";
import loading_JsMeH12IfP from "/opt/atlassian/pipelines/agent/build/plugins/loading.js";
import sentry_PZVGm6LH3G from "/opt/atlassian/pipelines/agent/build/plugins/sentry.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  plugin_client_UYiXMU8ZyN,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  i18n_yfWm7jX06p,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  google_login_8VXbHAWZpS,
  loading_JsMeH12IfP,
  sentry_PZVGm6LH3G
]