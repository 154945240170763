import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/opt/atlassian/pipelines/agent/build/middleware/01.auth.global.js";
import _02_45org_45id_45global from "/opt/atlassian/pipelines/agent/build/middleware/02.org-id.global.js";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45org_45id_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authorization: () => import("/opt/atlassian/pipelines/agent/build/middleware/authorization.js")
}